import React from 'react';
import { graphql, Link } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

import { MdChevronRight } from 'react-icons/md';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Button from '../components/Button';
import Hero from '../components/Hero';
import CallToAction from '../components/CallToAction';

const IndexPage = ({ data }) => (
  <Layout>
    <SEO
      meta_title={data.prismicHomePage.data.meta_title}
      meta_description={data.prismicHomePage.data.meta_description}
    />
    <Hero
      title={data.prismicHomePage.data.hero_text.text}
      className="py-24"
      overlay="bg-black opacity-50"
      fluid={data.prismicHomePage.data.hero_image.localFile.childImageSharp.fluid}
    />

    <section className="p-0 bg-primary">
      <div className="flex flex-wrap">
        <BackgroundImage
          className="w-full md:w-1/2 px-5 py-10 md:p-16 bg-grey-darkest hidden md:block"
          style={{
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
          }}
          fluid={data.prismicHomePage.data.image_intro.localFile.childImageSharp.fluid}
        />
        <div className="w-full md:w-1/2 px-5 py-10 md:p-16">
          <div className="w-full md:max-w-sm lg:max-w-md">
            <h2 className="title">{data.prismicHomePage.data.subheading.text}</h2>
            <div
              dangerouslySetInnerHTML={{
                __html: data.prismicHomePage.data.content.html,
              }}
              className="mb-8 text-white"
            />
            <Button text="About Us" className="button secondary" link="about" aria_label="About Us" />
          </div>
        </div>
      </div>
    </section>

    <section className="p-0 bg-grey-darkest text-grey-light">
      <div className="flex flex-wrap">
        <div className="w-full md:w-1/2 px-5 py-10 md:p-16 bg-grey-darker flex flex-wrap md:justify-end">
          <div className="w-full md:max-w-sm lg:max-w-md">
            <h2 className="title">Services</h2>
            <p>{data.prismicHomePage.data.services_intro.text}</p>
            <ul className="list-reset list-primary m-0 p-0 mt-8">
              {data.allPrismicService.edges.map(({ node }) => (
                <li key={node.id}>
                  <Link to={`services#${node.uid}`}>
                    {node.data.service_title.text}{' '}
                    <span>
                      <MdChevronRight />
                    </span>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="w-full md:w-1/2 px-5 py-10 md:p-16">
          <div className="w-full md:max-w-sm lg:max-w-md">
            <h2 className="title">Equipment Rentals</h2>
            <p>{data.prismicHomePage.data.rentals_intro.text}</p>
            <ul className="list-reset list-primary m-0 p-0 mt-8">
              {data.allPrismicEquipment.edges.map(({ node }) => (
                <li key={node.id}>
                  <Link to={`equipment-rentals/${node.uid}`}>
                    {node.data.equipment_title.text}{' '}
                    <span>
                      <MdChevronRight />
                    </span>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
    <CallToAction />
  </Layout>
);

export default IndexPage;

export const query = graphql`
  query homePage {
    prismicHomePage {
      id
      data {
        title {
          html
          text
        }
        subheading {
          html
          text
        }
        content {
          html
          text
        }
        hero_image {
          alt
          url
          localFile {
            id
            childImageSharp {
              fluid(maxWidth: 1600, quality: 80, cropFocus: CENTER) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        hero_text {
          html
          text
        }
        services_intro {
          html
          text
        }
        rentals_intro {
          html
          text
        }
        image_intro {
          alt
          url
          localFile {
            id
            childImageSharp {
              fluid(maxWidth: 800, quality: 80, cropFocus: CENTER) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        meta_title
        meta_description
      }
    }
    allPrismicService(sort: { fields: data___service_order___text }) {
      edges {
        node {
          id
          uid
          data {
            service_title {
              html
              text
            }
          }
        }
      }
    }
    allPrismicEquipment {
      edges {
        node {
          id
          uid
          data {
            equipment_title {
              html
              text
            }
          }
        }
      }
    }
  }
`;
